import PrivateAPI from './private'

export const testEmail = () => {
  return PrivateAPI.post('/api/test-email/');
};

export const getSummary = (month, year) => {
  return PrivateAPI.post('/api/summary/', {
    month: month,
    year: year
  });
};

export const getClients = (page, filters) => {
  // let params = { 'page': page }
  filters['page'] = page;
  return PrivateAPI.get('/api/clients/', filters);
};

export const searchClients = (query, page) => {
  return PrivateAPI.get('/api/clients/search/', {
    'query': query,
    'page': page
  });
};

export const getAllClients = () => {
  return PrivateAPI.get('/api/clients/all/');
};

export const getClient = (uid) => {
  return PrivateAPI.get('/api/clients/' + uid + '/');
};

export const getClientStats = (uid, source) => {
  return PrivateAPI.get('/api/clients/' + uid + '/stats/' + source + '/');
};

export const getClientContract = (uid) => {
  return PrivateAPI.get('/api/clients/' + uid + '/contract/');
};

export const getClientFullCatalog = (uid) => {
  return PrivateAPI.get('/api/clients/' + uid + '/catalog/');
};

export const partialClientUpdate = (uid, payload) => {
  return PrivateAPI.post('/api/clients/' + uid + '/partial/', payload);
};

export const clientLogoUpload = (uid, payload) => {
  return PrivateAPI.postfile('/api/clients/' + uid + '/logo/', payload);
};

export const generateClientInviteLink = (uid) => {
  return PrivateAPI.get('/api/clients/' + uid + '/invite-link/');
};

export const getClientMonthlyRoyalty = (uid, clientSource, month, year, endMonth, endYear) => {
  return PrivateAPI.post('/api/clients/' + uid + '/month-royalty/', {
    clientSource: clientSource,
    month: month,
    year: year,
    endMonth: endMonth,
    endYear: endYear
  });
};

export const mergeClients = (destUid, sourceId) => {
  return PrivateAPI.post('/api/clients/' + destUid + '/merge/', {
    source: sourceId
  });
};

export const clientYearOverYear = (uid, prevYear, year) => {
  return PrivateAPI.post('/api/clients/' + uid + '/year-over-year/', {
    'year': year,
    'prevYear': prevYear
  });
};

export const exportYearOverYear = (pk, prevYear, year) => {
  return PrivateAPI.post('/api/clients/' + pk + '/year-over-year/export/', {
    'year': year,
    'prevYear': prevYear
  });
}

export const exportYearOverYearCustom = (pk, prevYear, year) => {
  return PrivateAPI.post('/api/clients/' + pk + '/year-over-year/export-custom/', {
    'year': year,
    'prevYear': prevYear
  });
}

export const exportEndOfYear = (pk, year) => {
  return PrivateAPI.postExcel('/api/clients/' + pk + '/end-of-year/export/', {
    'year': year
  });
}

export const getClientSummaries = (uid, filter) => {
  return PrivateAPI.post('/api/clients/' + uid + '/summaries/', {
    'filter': filter
  });
};

export const getClientAssetTitles = (uid, params) => {
  return PrivateAPI.get('/api/clients/' + uid + '/assets/search/' , params);
};

// REPORTS
export const getReports = (page, query, pageCount = 20) => {
  return PrivateAPI.get('/api/reports/', {
    page: page,
    per_page: pageCount,
    query: query
  });
};

export const getReport = (id) => {
  return PrivateAPI.get('/api/reports/' + id + '/');
};

export const updateReport = (id, data) => {
  return PrivateAPI.post('/api/reports/' + id + '/', {
    data: data
  });
};

export const createReport = (payload) => {
  return PrivateAPI.post('/api/reports/', payload);
};

export const createReportAliasFromReport = (report, alias) => {
  return PrivateAPI.post('/api/reports/' + report + '/alias-from-report/', {
    alias: alias
  });
};

export const archiveReport = (report) => {
  return PrivateAPI.post('/api/reports/' + report + '/archive/', {});
};

export const getReportCategories = () => {
  return PrivateAPI.get('/api/reports/category/');
};

export const createReportCategory = (payload) => {
  return PrivateAPI.post('/api/reports/category/', payload);
};

export const getReportCategoriesOptions = () => {
  return PrivateAPI.get('/api/reports/category/options/');
};

export const getAssetLabels = (page, filters) => {
  filters['page'] = page;
  return PrivateAPI.get('/api/asset-labels/', filters);
};

export const assignAssetLabel = (clientId, assetLabelId) => {
  return PrivateAPI.post('/api/asset-labels/assign/', {
    'client': clientId,
    'asset_label': assetLabelId
  });
};

export const exportReport = (pk, reportName, startMonth, startYear, endMonth, endYear) => {
  var params = {}
  params['report_name'] = reportName
  params['start_month'] = startMonth
  params['start_year'] = startYear
  params['end_month'] = endMonth
  params['end_year'] = endYear
  return PrivateAPI.get('/api/clients/' + pk + '/report-export/', params);
}

export const getExports = (pk) => {
  return PrivateAPI.get('/api/clients/' + pk + '/reports/');
}

export const downloadReport = (pk, reportName, clientSource, startMonth, startYear) => {
  var params = {}
  params['report_name'] = reportName
  params['start_month'] = startMonth
  params['start_year'] = startYear
  params['client_source'] = clientSource

  return PrivateAPI.post(
    '/api/clients/' + pk + '/download-report/',
    params
  );
}

// User Downlaods
export const getUserDownloads = (page, query, pageCount = 20) => {
  return PrivateAPI.get('/api/user-downloads/', {
    page: page,
    per_page: pageCount,
    query: query
  });
};

export const getServices = () => {
  return PrivateAPI.get('/api/services/');
};

export const addServiceRate = (uid, data) => {
  return PrivateAPI.post(`/api/clients/${uid}/services/`, data);
};

export const editServiceRate = (uid, data) => {
  return PrivateAPI.put(`/api/clients/${uid}/services/`, data);
};

export const getClientServices = (uid) => {
  return PrivateAPI.get(`/api/clients/${uid}/services/`);
};

export const searchAsset = (data) => {
  return PrivateAPI.get('/api/assets/search/', data);
};
