import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';

import { exportEndOfYear } from 'api/core';
import { useTranslation } from 'react-i18next';
import { classNames } from 'components/utils';
import Spinner from 'components/Icons/Spinner'
import { template } from 'lodash';

const EndOfYear = ({ client }) => {
  const { t } = useTranslation();
  let location = useLocation();
  const basePath = location.pathname;
  const tempDate = new Date();
  tempDate.setMonth(tempDate.getMonth() - 3);
  const year = tempDate.getFullYear();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (client?.uid) {
      setLoading(false);
    }
  }, [client]);

  const getExport = () => {
    console.log('Download End of Year')
    setLoading(true);
    exportEndOfYear(client?.uid, 2024).then((res => {
      var data = new Blob([res?.data], { type: 'application / vnd. MS Excel' });
      var csvURL = window.URL.createObjectURL(data);
      var tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', `${client.name} - End of Year Report.xlsx`);
      tempLink.click();

      setLoading(false);
    })).catch(err => {
      console.log(err)
      setLoading(false);
    })
  };

  return (<>
    <section aria-labelledby="year-over-year">
      <div className="rounded-lg bg-white overflow-hidden shadow">
        <div className="col-span-1 flex rounded-md shadow-xs">
          <button
              className={classNames(
                'bg-green-500',
                'flex w-16 shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white',
                'hover:bg-green-600'
              )}
              onClick={() => getExport()}
            >
              {loading ? <Spinner /> : <DocumentArrowDownIcon className="w-8 h-8" />}
            </button>
            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
              <div className="flex-1 truncate px-4 py-2 text-sm">
                <span href={'#'} className="font-medium text-xl text-gray-900 hover:text-gray-600">
                  End of Year Report
                </span>
                <p className="text-gray-500">{'January to December 2024'}</p>
              </div>
            </div>
        </div>
      </div>
    </section>
  </>)
}

export default EndOfYear;
