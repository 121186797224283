import axios from "axios"

const buildUrl = (url, query) => {
  let finalUrl = process.env.REACT_APP_API_ROOT + url
  while (finalUrl.includes("//")) {
    finalUrl = finalUrl.replace("//", "/")
  }
  let tmpUrl = new URL(finalUrl)
  if (query) {
    var searchParams = tmpUrl.searchParams
    Object.keys(query).map((key) => searchParams.set(key, query[key]))
    tmpUrl.search = searchParams.toString()
  }
  return tmpUrl.toString()
}

const _getAccessToken = () => {
  return localStorage.getItem("accessToken")
}

export const _getRefreshToken = () => {
  return localStorage.getItem("refreshToken")
}

const getFormData = (object) =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key])
    return formData
  }, new FormData())

const PrivateAPI = {
  get: (url, query) => {
    return axios({
      method: "get",
      url: buildUrl(url, query),
      headers: {
        Authorization: "Bearer " + _getAccessToken(),
      },
    })
  },

  post: (url, payload) => {
    const fUrl = buildUrl(url, null)
    return axios({
      method: "post",
      url: fUrl,
      data: payload,
      headers: {
        Authorization: "Bearer " + _getAccessToken(),
      },
    })
  },

  put: (url, payload) => {
    const fUrl = buildUrl(url, null)
    return axios({
      method: 'put',
      url: fUrl,
      data: payload,
      headers: {
        'Authorization': 'Bearer ' + _getAccessToken()
      }
    })
  },

  postfile: (url, payload) => {
    const formData = getFormData(payload)
    return axios({
      method: "post",
      url: buildUrl(url),
      data: formData,
      headers: {
        Authorization: "Bearer " + _getAccessToken(),
      },
    })
  },

  delete: (url) => {
    const fUrl = buildUrl(url, null)
    return axios({
      method: "delete",
      url: fUrl,
      headers: {
        Authorization: "Bearer " + _getAccessToken(),
      },
    })
  },

  postExcel: (url, payload) => {
    const formData = getFormData(payload)
    return axios({
      method: "post",
      url: buildUrl(url),
      data: formData,
      headers: {
        Authorization: "Bearer " + _getAccessToken(),
      },
      responseType: 'blob'
    })
  },
}

export default PrivateAPI
